
































import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterTypeService } from "@/services-v2/master-type.service";
import { IOption } from "@interface/common.interface";
import Vue from "vue";

export default Vue.extend({
  name: "CSelectTranslatorType",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dtOpt: [] as IOption[],
      loading: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    async getList(): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterType({name: "TRANSLATOR_TYPE"});
        this.dtOpt = res.map(x => { return { key: x.value, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    onSelect(e): void {
      this.$emit("input", e);
      this.$emit("on-select", e);
    },
    clear(): void {
      this.$emit("input", undefined);
    },
  },
});
